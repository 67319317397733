<template>
  <b-container>
    <b-card>
      <b-card-body>
        <StoryBlokContent slug="cdn/stories/contact" />
      </b-card-body>
    </b-card>
  </b-container>
</template>
<script>
import StoryBlokContent from '../components/StoryBlokContent.vue'

export default {
  components: { StoryBlokContent }

}
</script>
