<template>
  <div>
    <StoryblokComponent
      :blok="blok"
      :key="blok._uid"
      v-for="blok in content.body"
    />
  </div>
</template>

<script>
export default {
  name: 'StoryblokContent',
  components: {},
  mixins: [],
  props: {
    slug: {
      type: String,
      default: null,
      immediate: false,
      handler: function (val) {
        console.log(val)
        this.getContent()
      }
    }
  },
  data () {
    return {
      content: { body: [] }
    }
  },
  computed: {
    lang: function () {
      return this.$root.$i18n.locale
    }
  },
  watch: {
    lang: {
      handler: function (newval, oldval) {
        if (newval !== oldval) {
          console.log(newval)
          this.getContent()
        }
      }
    }
  },
  mounted () {
    if (this.slug) {
      this.getContent()
    }
  },
  methods: {
    getContent () {
      console.log('lang', this.lang, this.slug)
      this.$storyblokApi.get(this.slug, {
        version: 'published',
        language: this.lang
      }).then((response) => {
        console.log(response)
        this.content = response.data.story.content
        // console.log('content', this.content)
      })
    }
  }

}
</script>
